import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

const CommonLink = ({ link, children, ...props }) => {
  if(!link) {
    return null
  }

  if(link.url) {
    return (
      <Link to={ link.url } target="_blank" { ...props }>
        <span>{ link.text }</span>
        { children }
      </Link>
    )
  }
  else if(link.page) {
    return (
      <Link to={ link.page.path } { ...props }>
        <span>{ link.text }</span>
        { children }
      </Link>
    ) 
  }
  else {
    return (
      <Link to="/" { ...props }>
        <span>{ link.text }</span>
        { children }
      </Link>
    )
  }
}

export default CommonLink