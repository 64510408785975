// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

const NavbarItem = ({ navbarItem }) => {
  if(_.get(navbarItem, "link.page.path")) {
    return (
      <div className={ `NavbarItem navbar-item ${ navbarItem.uuid || "" }` }>
        <Link to={ navbarItem.link.page.path }>
          <span>{ navbarItem.name }</span>
          {
            navbarItem.uuid === "topNav_contactUs" &&
              <span className="icon">
                <img src={ require("src/images/icons/arrow-right-white.png").default } />
              </span>
          }
        </Link>
      </div>
    )
  }
  else if(_.get(navbarItem, "link.url")) {
    return (
      <div className={ `NavbarItem navbar-item ${ navbarItem.uuid }` }>
        <Link to={ navbarItem.link.url } target="_blank">
          <span>{ navbarItem.name }</span>
        </Link>
      </div>
    )
  }
  else if(_.get(navbarItem, "navMenuLinks", []).length > 0) {
    return (
      <div className={ `NavbarItem navbar-item has-dropdown is-hoverable ${ navbarItem.uuid || ""}` }>
        <a className="navbar-link">
          { navbarItem.name }
        </a>
        <div className="navbar-dropdown">
          {
            navbarItem.navMenuLinks.map((navMenuLink, navMenuLinkIndex) => {
              return (
                <Link 
                  to={ _.get(navMenuLink, `page.path`, `/`) } 
                  key={ `navMenuLink-${ navMenuLinkIndex }` }
                  className={ `navbar-item ${ navMenuLink.isSubMenu ? "is-submenu" : "" }` }>
                    { navMenuLink.text }
                </Link>
              )
            })
          }
        </div>
      </div>
    )
  }

  return null;
}

export default NavbarItem