// NPM
import React, { useState } from "react"
import { Link } from 'gatsby'
import _ from 'lodash'

// Components
import NavbarItem from './NavbarItem';

const Header = ({ topNav, langs }) => {
  // State
  const [viewingNav, setViewingNav] = useState(false)

  const currentLanguage = langs.find(l => l.selected)

  return (
    <header className="Header">
      <div className="container">
        <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to={ `/${ currentLanguage.langKey === "en" ? '' : currentLanguage.langKey }` }>
              <img src={ require("src/images/logos/vpl-logo.png").default } />        
            </Link>

            <a 
              onClick={ () => setViewingNav(!viewingNav) }
              role="button" 
              className={ `navbar-burger ${ viewingNav ? 'is-active' : '' }` } 
              aria-label="menu" 
              aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="TopNav" className={ `navbar-menu ${ viewingNav ? 'is-active' : '' }` }>
            <div className="navbar-end">
              {
                _.get(topNav, "menus", []).map((navbarItem, navbarItemIndex) => {
                  return (
                    <NavbarItem
                      key={ `NavbarItem-${ navbarItemIndex }` }
                      navbarItem={ navbarItem }
                    />
                  )
                })
              }
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
