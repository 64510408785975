// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

const FooterBottomSocial = ({ socialMedia }) => {
  return (
    <ul 
      className="FooterBottomSocial">
      { 
        socialMedia.map((socialMedia, socialMediaIndex) => {
          return (
            <li
              className="FooterBottomSocial__item"  
              key={ `FooterBottomSocial__item-${ socialMediaIndex }` }>
                <Link to={ _.get(socialMedia, "url", "/") } target="_blank">
                  <img 
                    className={ `FooterBottomSocial__item-image ${ _.get(socialMedia, `uuid`) }` }
                    src={ _.get(socialMedia, `icon.url`) } 
                  />
                </Link>
            </li>
          )
        })
      }
    </ul>
  )

}

export default FooterBottomSocial