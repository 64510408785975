// NPM
import React from 'react'

// Components
import FooterBottomLinks from './FooterBottomLinks'
import FooterBottomSocial from './FooterBottomSocial'

const FooterBottom = ({ sections, socialMedia }) => {

  const descriptionSection = sections.find(s => s.uuid === "bottomNav_footerDisclaimer")

  return (
    <div className="FooterBottom">
      <div className="FooterBottom__left">
        {
          descriptionSection &&
            <p className="FooterBottom__left-description">
              { descriptionSection.title } 
            </p>
        }

        <FooterBottomLinks sections={ sections } />
      </div>

      <div className="FooterBottom__right">
        <FooterBottomSocial socialMedia={ socialMedia } />
      </div>
    </div>
  )

}

export default FooterBottom