// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

// Shared Components
import CommonLink from 'src/components/Shared/CommonLink'

const FooterMenu = ({ menu }) => {

  return (
    <div className="FooterMenu">
      <h4 className="FooterMenu__title">
        { menu.name }
      </h4>

      <div className="FooterMenu__links">
        {
          menu.navMenuLinks.map((link, linkIndex) => {
            return (
              <CommonLink 
                link={ link }
                className="FooterMenu__link"
                key={ `FooterMenu__link-${ linkIndex }` }
              />
            )
          })
        }
      </div>
    </div>
  )

}

export default FooterMenu;
