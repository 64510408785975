// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

const FooterBottomLinks = ({ sections }) => {
  const linkSections = sections.filter(s => s.uuid === "bottomNav_footerLink")
  
  return (
    <ul 
      className="FooterBottomLinks">
      { 
        linkSections.map((linkSection, linkSectionIndex) => {
          return (
            <li
              className="FooterBottomLinks__item"  
              key={ `FooterBottomLinks__item-${ linkSectionIndex }` }>
                <Link to={ _.get(linkSection, "link.page.path", "/") }>
                  { _.get(linkSection, "link.text", "") }
                </Link>
            </li>
          )
        })
      }
    </ul>
  )

}

export default FooterBottomLinks