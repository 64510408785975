// NPM
import React from 'react'
import { Link } from 'gatsby'

const renderFooterLogoSection = (section, sectionIndex) => {
  const media = section.media[0];

  if(section.link) {
    return (
      <Link 
        key={ `FooterLogos__image-${ sectionIndex }` }
        to={ section.link.url } 
        target="_blank">
          <img 
            className={ `FooterLogos__image ${ media.uuid }` }
            src={ media.image.url } 
            alt={ media.image.imageAlt } 
          />
      </Link>
    )
  }
  else {
    return (
      <img 
        className={ `FooterLogos__image ${ media.uuid }` }
        key={ `FooterLogos__image-${ sectionIndex }` }
        src={ media.image.url } 
        alt={ media.image.imageAlt } 
      />
    )
  }
}

const FooterLogos = ({ sections }) => {
  const footerLogoSections = sections.filter(s => s.uuid === "bottomNav_logos")

  return (
    <div className="FooterLogos">
      { footerLogoSections.map(renderFooterLogoSection) }
    </div>
  )

}

export default FooterLogos