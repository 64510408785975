import React from 'react'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'


const Description = ({ description, className }) => {
  return (
    <ReactMarkdown
      children={ _.get(description, `data.description`, '') }
      className={ `Description ${ className }` }
      components={{
        u: ({node, ...props}) => {
          return <u {...props} />
        }
      }}
      rehypePlugins={[rehypeRaw]}
    />
  )
}

export default Description