// NPM
import React from 'react'
import _ from 'lodash'

// Components
import FooterMenu from './FooterMenu'
import PanelSignup from './PanelSignup'
import FooterLogos from './FooterLogos'
import FooterBottom from './FooterBottom'

const Footer = ({ bottomNav, socialMedia }) => {
  const sections = _.get(bottomNav, "sections", [])

  return (
    <footer className="Footer">
      <div className="Footer__inner">
        <div className="Footer__menus">
          {
            bottomNav.menus.map((menu, menuIndex) => {
              return (
                <FooterMenu 
                  menu={ menu } 
                  key={ menuIndex } 
                  key={ `FooterMenu-${ menuIndex }` }
                />
              )
            })
          }
        </div>
        <PanelSignup section={ sections.find(s => s.uuid === "bottomNav_panel") } />
        <FooterLogos sections={ sections } />
        <FooterBottom sections={ sections } socialMedia={ socialMedia } />
      </div>
    </footer>
  )

}

export default Footer;
