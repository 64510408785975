import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from 'react-intl';
import 'intl';
import _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

// GraphQL
import * as fragments from 'src/graphql/fragments';

// Styles
import "src/styles/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-vertical-timeline-component/style.min.css';

// Components
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'

// Font Awesome
library.add(fas)

const Layout = ({ topNav, bottomNav, page, socialMedia, children, location, i18nMessages }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                langs
                defaultLangKey
              }
            }
          }
        }
      `}
      render={ data => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
        const metaImage = _.get(page, `seo.metaImage.url`)
        
        return (
          <IntlProvider locale={ langKey }>
            <div>
              <Helmet>
                <title>{ _.get(page, `seo.metaTitle`, "") }</title>
                <meta name="og:type" content="website" />
                <meta property="og:title" content={ _.get(page, `seo.metaTitle`) } />
                <meta property="description" content={ _.get(page, `seo.metaDescription`, "") } />
                <meta property="og:image" content={ metaImage } />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={ _.get(page, `seo.twitterHandle`) } />
                <meta name="twitter:title" content={ _.get(page, `seo.metaTitle`) } />
                <meta name="twitter:description" content={ _.get(page, `seo.metaDescription`) } />
                <meta name="twitter:image" content={ metaImage } />
              </Helmet>
              
              <Header 
                topNav={ topNav } 
                langs={ langsMenu } 
              />

              { children }

              { bottomNav && <Footer bottomNav={ bottomNav } socialMedia={ socialMedia } /> }
            </div>
          </IntlProvider>
        )
      }}
    />
  );
};

export default Layout