// NPM
import React from 'react'
import _ from 'lodash'

// Components
import Description from 'src/components/Shared/Description'

const PanelSignup = ({ section }) => {

  if(!section) {
    return null
  }

  return (
    <div className="PanelSignup">
      <h4 className="PanelSignup__title">
        { section.title }
      </h4>

      <Description
        className="PanelSignup__description"
        description={ section.description }
      />

      {
        section.link && 
          <a 
            href={ section.link.url } 
            className="PanelSignup__cta black-button"
            target="_blank">
              <span>{ section.link.text }</span>
              <span className="icon">
                <img src={ require("src/images/icons/arrow-right-white.png").default } />
              </span>
          </a>
      }
    </div>
  )
}

export default PanelSignup